<template>
  <div>
    <DirectPayment v-bind:show="showDirectPayment" @hideDirectPayment="showDirectPayment = false" />
    <Nav page='payment' />
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="relative mt-10">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-start">
          <span class="pr-3 bg-gray-100 text-lg font-medium text-gray-900">
            Dina betalningar
          </span>
        </div>
      </div>

      <div class="mt-6 flex flex-col justify-stretch">
        <button @click="createManualPaymentRequest" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Sponsra direkt
        </button>
      </div>


      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="bg-white shadow overflow-hidden sm:rounded-md mt-5">
        <ul class="divide-y divide-gray-200">

            <li v-for="upcomingPayment in upcomingPayments" :key="upcomingPayment.date" class="opacity-50">
              <div class="px-4 py-4 flex items-center sm:px-6">

                <div class="flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-10 w-10 text-rose-600">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>

                <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between pl-5">
                  <div>
                    <div class="flex text-sm font-medium text-rose-600 truncate">
                      <p>Swish {{upcomingPayment.amount}} SEK</p>
                    </div>
                    <div class="mt-2 flex">
                      <div class="flex items-center text-sm text-gray-500">
                        <p>
                          Schemalagd betalning 
                          <time v-bind:datetime="upcomingPayment.date">{{upcomingPayment.date}}</time>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 flex-shrink-0 sm:mt-0">
                    <div class="flex overflow-hidden">
                      <img v-for="(spec, index) in upcomingPayment.specification" :key="spec.id" v-bind:class="{'-ml-1': index > 0}" class="inline-block h-6 w-6 rounded-full ring-2 ring-white" v-bind:src="spec.logo" alt="">
                    </div>
                  </div>
                </div>
                <div class="ml-5 flex-shrink-0">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
          </li>

          <li v-for="payment in payments" :key="payment.id">
            <router-link :to="'/payment/'+payment.request_code" class="block hover:bg-gray-50">
              <div class="px-4 py-4 flex items-center sm:px-6">

                <div class="flex-shrink-0">
                  <svg v-if="(payment.request_filled == 0 && payment.request_finished_at == null)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-10 w-10 text-rose-600" >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                  <svg v-if="(payment.request_filled == 1 && payment.request_finished_at != null)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-10 w-10 text-rose-600" >
                    <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
                  </svg>
                  <svg v-if="(payment.request_filled == 0 && payment.request_finished_at != null)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-10 w-10 text-rose-600" >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>

                <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between pl-5">
                  <div>
                    <div class="flex text-sm font-medium text-rose-600 truncate">
                      <p>Swish {{payment.amount}} SEK</p>
                    </div>
                    <div class="mt-2 flex">
                      <div class="flex items-center text-sm text-gray-500">
                        <p v-if="(payment.request_filled == 0 && payment.request_finished_at == null)">
                          Betalning genererad 
                          <time v-bind:datetime="payment.request_generated_at">{{payment.request_generated_at}}</time>
                        </p>
                        <p v-if="(payment.request_filled == 1 && payment.request_finished_at != null)">
                          Betalning genomförd 
                          <time v-bind:datetime="payment.request_finished_at">{{payment.request_finished_at}}</time>
                        </p>
                        <p v-if="(payment.request_filled == 0 && payment.request_finished_at != null)">
                          Betalningen kunde inte genomföras.  
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 flex-shrink-0 sm:mt-0">
                    <div class="flex overflow-hidden">
                      <img v-for="(spec, index) in payment.specification" :key="spec.id" v-bind:class="{'-ml-1': index > 0}" class="inline-block h-6 w-6 rounded-full ring-2 ring-white" v-bind:src="spec.logo" alt="">
                    </div>
                  </div>
                </div>
                <div class="ml-5 flex-shrink-0">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <Footer/>
    <Notification/>
  </div>
</template>

<style>
body {
  --tw-bg-opacity: 1;
  background-color:  rgba(243, 244, 246, var(--tw-bg-opacity));;
}
</style>

<script>
import DirectPayment from './DirectPayment.vue'
import Nav from './Nav.vue'
import Notification from './Notification.vue'
import Footer from './Footer.vue'

export default {
  name: 'Payments',
  components: {
    DirectPayment,
    Nav,
    Notification,
    Footer
  },
  data: function() {
    return {
      showDirectPayment: false,
      token: null,
      payments: [],
      activePayments: [],
      upcomingPayments: []
    }
  },
  beforeMount(){
    this.checkToken()
    this.getPayments()
  },
  methods: {
    checkToken() {
      let token = localStorage.getItem('token');
      if(token == null) {
        window.location.href = "/";
      } else {
        this.token = token;
      }
    },
    getPayments(){
      this.getData('https://api.sponsr.se/payments?token='+this.token)
        .then(data => {
          this.payments = data.previous;
          this.upcomingPayments = data.upcoming;
        });
      // console.log(this.name, this.price);
    },
    createManualPaymentRequest() {
      this.showDirectPayment = true;
    },
    async postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }

}
</script>