<template>
  <div>
    <Purpose v-bind:show="showPurposePanel" v-bind:purpose="organisationDetails" @hidePurposePanel="showPurposePanel = false" />
    <Nav page='purposes' />
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <form autocomplete="off">
        <div class="mt-5">
          <label for="search" class="sr-only">Hitta ändamål</label>
          <input type="text" v-model="query" v-on:keyup="getFilteredOrganisations()" name="search" id="search" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md" placeholder="Ange ditt sökord">
        </div>
      </form>

        <div v-if="filtered.length > 0" class="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-5">
          <div v-for="organisation in filtered" :key="organisation.id">
            <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-rose-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-rose-500">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" v-bind:src="organisation.logo" alt="">
              </div>
              <div class="flex-1 min-w-0">
                <a href="#" class="focus:outline-none" @click="toggleOrganisation(organisation, $event)">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  <p class="text-sm font-medium text-gray-900">
                    {{organisation.name}}
                  </p>
                  <p class="text-sm text-gray-500 truncate">
                    {{organisation.category_name}}
                  </p>
                </a>
              </div>
              <div class="flex-shrink-0" v-if="organisation.active != null">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-10 w-10 text-rose-600">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

      <div v-if="query == ''">
      <div v-for="category in categories" :key="category.id" >
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="relative mt-10">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-start">
            <span class="pr-3 bg-gray-100 text-lg font-medium text-gray-900">
              {{category.name}}
            </span>
          </div>
        </div>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-5">
          <div v-for="organisation in category.organisations" :key="organisation.id">
            <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-rose-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-rose-500">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" v-bind:src="organisation.logo" alt="">
              </div>
              <div class="flex-1 min-w-0">
                <div class="focus:outline-none cursor-pointer" @click="toggleOrganisation(organisation, $event)">
                  
                  <p class="text-sm font-medium text-gray-900">
                    {{organisation.name}}
                  </p>
                  <p class="text-sm text-gray-500 truncate">
                    {{category.name}}
                  </p>
                  <p>
                    <button @click.stop @click="showPurposePanel = true; organisationDetails = organisation;" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-rose-500 text-white">
                      Läs mer
                    </button>
                    <span v-for="tag in organisation.tags" :key="tag.id" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {{tag.title}}
                    </span>
                  </p>
                </div>
              </div>
              <div class="flex-shrink-0" v-if="organisation.active != null">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-10 w-10 text-rose-600">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>
    <Footer/>
    <Notification/>
  </div>
</template>

<style>
body {
  --tw-bg-opacity: 1;
  background-color:  rgba(243, 244, 246, var(--tw-bg-opacity));;
}
</style>

<script>

import Purpose from './Purpose.vue'
import Nav from './Nav.vue'
import Notification from './Notification.vue'
import Footer from './Footer.vue'

export default {
  name: 'Home',
  components: {
    Purpose,
    Nav,
    Notification,
    Footer
  },
  props: {
    page: String
  },
  data: function() {
    return {
      showPurposePanel: false,
      organisationDetails: {},
      token: null,
      query: "",
      categories: [],
      filtered: [],
    }
  },
  beforeMount(){
    this.checkToken()
    this.getCategorizedOrganisations()
  },
  methods: {
    x() {
      alert("Helo");
    },
    checkToken() {
      let token = localStorage.getItem('token');
      if(token == null) {
        window.location.href = "/";
      } else {
        this.token = token;
      }
    },
    getFilteredOrganisations() {
      this.filtered = [];
      if(this.query != "") {
        this.categories.forEach(_cat => {
          _cat.organisations.forEach(_org => {
            let res = _org.name.toLowerCase().includes(`${this.query}`);
            if(res) {
              this.filtered.push(_org);
            }
          });
        });
      }
      
    },
    getCategorizedOrganisations(){
      this.getData(`https://api.sponsr.se/organisations?token=${this.token}`)
        .then(data => {
          this.categories = data;
        });
    },
    toggleOrganisation(organisation, event) {
      event.preventDefault();
      if(organisation.active == null) {
        this.postData(`https://api.sponsr.se/organisations/${organisation.id}?token=${this.token}`)
        .then(data => {
          if(data.success) {
            organisation.active = "0";
            console.log("Organisation added");
          }
        });
      } else {
        this.deleteData(`https://api.sponsr.se/organisations/${organisation.id}?token=${this.token}`)
        .then(data => {
          if(data.success) {
            organisation.active = null;
            console.log("Organisation deleted");
          }
        });
      }
      
    },
    async postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    async deleteData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }

}
</script>