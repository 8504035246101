<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="mt-20">
  
  </div>
</template>

<script>

export default {
  name: 'Footer'
}
</script>