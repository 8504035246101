<template>
   <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
        enter-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
        leave-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <div class="fixed inset-0 overflow-hidden z-50" v-if="show" @click="hidePanel">
          <div class="absolute inset-0 overflow-hidden">
            <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" aria-labelledby="slide-over-heading" @click.stop>
              <div class="w-screen max-w-2xl">
                <form @submit="createManualPaymentRequest" class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="px-4 py-6 bg-gray-50 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">
                            Sponsra direkt
                          </h2>
                          <p class="text-sm text-gray-500">
                            Extra pengar över? Sponsra dina favoritändamål en gång utan att det påverkar ditt månadsgivande!
                          </p>
                        </div>
                        <div class="h-7 flex items-center">
                          <button type="button" @click="hidePanel" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rose-500">
                            <span class="sr-only">Stäng panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      <!-- Project name -->
                      <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label for="project_name" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                            Att sponsra
                          </label>
                        </div>
                        <div class="sm:col-span-2">



                            <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                                <a href="#" @click.prevent="user.amount = 100" :class=" {'bg-rose-600 text-white' : user.amount == 100, 'bg-white text-rose-600' : user.amount != 100}" class="rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>100kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 200" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 200, 'bg-white text-rose-600' : user.amount != 200}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>200kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 300" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 300, 'bg-white text-rose-600' : user.amount != 300}" class=" group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>300kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 400" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 400, 'bg-white text-rose-600' : user.amount != 400}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>400kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 500" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 500, 'bg-white text-rose-600' : user.amount != 500}" class="rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>500kr</span>
                                </a>
                            </nav>

                            <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mt-5" aria-label="Tabs">
                                <a href="#" @click.prevent="user.amount = 1000" :class=" {'bg-rose-600 text-white' : user.amount == 1000, 'bg-white text-rose-600' : user.amount != 1000}" class="rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>1000kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 2000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 2000, 'bg-white text-rose-600' : user.amount != 2000}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>2000kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 3000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 3000, 'bg-white text-rose-600' : user.amount != 3000}" class=" group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>3000kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 4000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 4000, 'bg-white text-rose-600' : user.amount != 4000}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>4000kr</span>
                                </a>

                                <a href="#" @click.prevent="user.amount = 5000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 5000, 'bg-white text-rose-600' : user.amount != 5000}" class="rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                    <span>5000kr</span>
                                </a>
                            </nav>

                            <div class="col-span-6 sm:col-span-3 mt-5">
                                <label for="sponsor_amount" class="block text-sm font-medium text-gray-700">Egen summa</label>
                                <input type="text" v-model="user.amount" id="sponsor_amount"  class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                            </div>
                        </div>
                      </div>

                      <!-- Team members -->
                      <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                        <div>
                          <h3 class="text-sm font-medium text-gray-900">
                            Ändamål
                          </h3>
                        </div>
                        
                        <div class="sm:col-span-2">
                            <div v-for="organisation in organisations" :key="organisation.id">
                                <div v-bind:class="{'opacity-50' : organisation.active == 0}" class="mt-5 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-rose-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-rose-500">
                                    <div class="flex-shrink-0">
                                    <img class="h-10 w-10 rounded-full" v-bind:src="organisation.logo" alt="">
                                    </div>
                                    <div class="flex-1 min-w-0">
                                    <a href="#" class="focus:outline-none" v-on:click="toggleActive(organisation, $event)">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        <p class="text-sm font-medium text-gray-900">
                                        {{organisation.name}}
                                        </p>
                                        <p class="text-sm text-gray-500 truncate">
                                        {{organisation.category_name}}
                                        </p>
                                    </a>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                      </div>

                      <!-- Privacy -->
                      <fieldset>
                        <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-5">
                          <div>
                            <legend class="text-sm font-medium text-gray-900">
                              Inställningar
                            </legend>
                          </div>
                          <div class="space-y-5 sm:col-span-2">
                            <div class="space-y-5 sm:mt-0">
                              <div class="relative flex items-start">
                                <div class="absolute flex items-center h-5">
                                  <input id="direct_payment" v-model="user.saveSettings" value="false" name="payment_type" aria-describedby="direct_payment_description" type="radio" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300" checked>
                                </div>
                                <div class="pl-7 text-sm">
                                  <label for="direct_payment" class="font-medium text-gray-900">
                                      Genomför direktsponsring
                                    
                                  </label>
                                  <p id="direct_payment_description" class="text-gray-500">
                                      Dina befintliga inställningar bibehålls
                                    
                                  </p>
                                </div>
                              </div>

                              <div class="relative flex items-start">
                                <div class="absolute flex items-center h-5">
                                  <input id="save_payment" v-model="user.saveSettings" value="true" name="payment_type" aria-describedby="save_payment_description" type="radio" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300">
                                </div>
                                <div class="pl-7 text-sm">
                                  <label for="save_payment" class="font-medium text-gray-900">
                                    Spara som framtida månadsbetalning
                                  </label>
                                  <p id="save_payment_description" class="text-gray-500">
                                    Beloppet samt ändamålen sparas
                                  </p>
                                </div>
                              </div>
                            </div>
                            <hr class="border-gray-200">
                            <div class="flex flex-col space-between space-y-4 sm:flex-row sm:items-center sm:space-between sm:space-y-0">
                              <div class="flex-1">
                                <a href="#" class="group flex items-center text-sm text-rose-600 hover:text-rose-900 font-medium space-x-2.5">
                                  <!-- Heroicon name: solid/link -->
                                  <svg class="h-5 w-5 text-rose-500 group-hover:text-rose-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                                  </svg>
                                  <span>
                                    Dela länk
                                  </span>
                                </a>
                              </div>
                              <div>
                                <a href="#" class="group flex items-center text-sm text-gray-500 hover:text-gray-900 space-x-2.5">
                                  <!-- Heroicon name: solid/question-mark-circle -->
                                  <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                  </svg>
                                  <span>
                                    Lär dig mer om var pengarna går
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    <div class="space-x-3 flex justify-end">
                      <button type="button" @click="hidePanel" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                        Avbryt
                      </button>
                      <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                        Sponsra
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
    </transition>


</template>

<script>

export default {
  name: 'DirectPayment',
  props: ['show'],
  data: function() {
      return {
          user: {
              amount: 500,
              saveSettings: false,
          },
          token: null,
          organisations: [],
      }
  },
  beforeMount(){
      this.checkToken()
      this.getSponsored()
  },
  methods: {
    hidePanel: function() {
        this.$emit('hideDirectPayment');
    },
    checkToken() {
      let token = localStorage.getItem('token');
      if(token == null) {
        window.location.href = "/";
      } else {
        this.token = token;
      }
    },
    getSponsored(){
      this.getData('https://api.sponsr.se/sponsored?token='+this.token)
        .then(data => {
          this.organisations = data;
        });
      // console.log(this.name, this.price);
    },
    toggleActive(organisation, event) {
      event.preventDefault()
      if(organisation.active == 0) {
        organisation.active = 1;
      } else {
        organisation.active = 0;
      }
    },
    createManualPaymentRequest(event) {
        event.preventDefault();
        let sponsor = [];
        this.organisations.forEach(_org => {
            if(_org.active == 1) {
                sponsor.push(_org.id);
            }
        });
        let data = {
            saveSettings: this.user.saveSettings,
            amount: this.user.amount,
            organisations: sponsor
        };
        this.postData('https://api.sponsr.se/payments?token='+this.token, data)
        .then(data => {
          console.log(data);
        });
    },
    async postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }
}
</script>