<template>
    <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5" v-if="!hidden && notification != null">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="p-2 rounded-lg bg-rose-600 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-rose-800">
                <!-- Heroicon name: outline/speakerphone -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
                <span class="md:hidden">
                {{notification.short_text}}
                </span>
                <span class="hidden md:inline">
                {{notification.long_text}}
                </span>
            </p>
            </div>
            <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a v-bind:href="notification.link" target="_blank" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-rose-600 bg-white hover:bg-rose-50">
                Läs mer
            </a>
            </div>
            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            <button @click="hideNotification(notification)" type="button" class="-mr-1 flex p-2 rounded-md hover:bg-rose-500 focus:outline-none focus:ring-2 focus:ring-white">
                <span class="sr-only">Dismiss</span>
                <!-- Heroicon name: outline/x -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>

<script>

export default {
  name: 'Notification',
  props: ['page'],
  data: function() {
      return {
          notification: null,
          hidden: false
      }
  },
  beforeMount(){
    this.getNotifications()
  },
  methods: {
      checkNotificationHidden(uid) {
          if(localStorage.getItem(uid) !== null) {
              return false;
          } else {
              return true;
          }
      },
      hideNotification(notification) {
          localStorage.setItem(notification.uid, true)
          this.hidden = !this.hidden
      },
      getNotifications() {
          this.getData('https://api.sponsr.se/notifications')
            .then(data => {
                if(this.checkNotificationHidden(data.uid)) {
                    this.notification = data;
                }
            });
      },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }
}
</script>