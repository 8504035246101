<template>
  <div>

 
  <DirectPayment v-bind:show="showDirectPayment" @hideDirectPayment="showDirectPayment = false" />
  <Nav page='dashboard' />



	<div v-if="totalSponsoring == 0" class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mt-20">
    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
      <span class="block">Redo att börja sponsra?</span>
      <span class="block">Välj ändamål, hur mycket ofta!</span>
    </h2>
    <div class="mt-8 flex justify-center">
      <div class="inline-flex rounded-md shadow">
        <router-link to="/purposes" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700">
          Välj ändamål
        </router-link>
      </div>
      <div class="ml-3 inline-flex">
        <router-link to="/settings" class="inline-flex items-center justify-center px-5 py-3 border border-rose-600 text-base font-medium rounded-md text-rose-700 bg-white hover:bg-gray-50">
          Hur mycket och ofta
        </router-link>
      </div>
    </div>
  </div>
	

  
  

  <div v-if="totalSponsoring > 0" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

      <dl class="mt-5 grid grid-cols-3 gap-5 sm:grid-cols-3">
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
              Sponsring
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-rose-600">
              {{statistics.totalAmount}} kr
            </dd>
          </div>
        </div>

        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
              Ändamål
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-rose-600">
              {{activeSponsoring}}/{{totalSponsoring}} st
            </dd>
          </div>
        </div>

        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
              Avgifter
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-rose-600">
              {{statistics.totalFees}} kr
            </dd>
          </div>
        </div>
      </dl>

      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="relative mt-10">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-start">
          <span class="pr-3 bg-gray-100 text-lg font-medium text-gray-900">
            Din sponsring
          </span>
        </div>
      </div>


      <div class="mt-6 flex flex-col justify-stretch">
        <button @click="createManualPaymentRequest" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Sponsra direkt
        </button>
      </div>


      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-5">
        <div v-for="organisation in organisations" :key="organisation.id">
          <div v-bind:class="{'opacity-50' : organisation.active == 0}" class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-rose-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-rose-500">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" v-bind:src="organisation.logo" alt="">
            </div>
            <div class="flex-1 min-w-0">
              <a href="#" class="focus:outline-none" v-on:click="toggleActive(organisation, $event)">
                <span class="absolute inset-0" aria-hidden="true"></span>
                <p class="text-sm font-medium text-gray-900">
                  {{organisation.name}}
                </p>
                <p class="text-sm text-gray-500 truncate">
                  {{organisation.category_name}}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
  </div>
    
    <Footer/>
    <Notification/>
  </div>
</template>

<style>
body {
  --tw-bg-opacity: 1;
  background-color:  rgba(243, 244, 246, var(--tw-bg-opacity));;
}
</style>

<script>

import DirectPayment from './DirectPayment.vue'
import Nav from './Nav.vue'
import Notification from './Notification.vue'
import Footer from './Footer.vue'

export default {
  name: 'Home',
  components: {
    DirectPayment,
    Nav,
    Notification,
    Footer
  },
  data: function() {
    return {
      showDirectPayment: false,
      show: false,
      token: null,
      organisations: [],
      activeSponsoring: 0,
      totalSponsoring: 0,
      statistics: {
        totalAmount: 0,
        totalFees: 0,
        totalCount: 0,
      }
    }
  },
  beforeMount(){
    this.checkToken()
    this.getSponsored()
    this.getStatistics()
  },
  methods: {
    checkToken() {
      let token = localStorage.getItem('token');
      if(token == null) {
        window.location.href = "/";
      } else {
        this.token = token;
      }
    },
    countSponsored() {
      this.activeSponsoring = 0;
      this.totalSponsoring = 0;

      this.organisations.forEach(_org => {
        if(_org.active == 1) {
          this.activeSponsoring++;
        }
        this.totalSponsoring++;
      })
    },
    getStatistics(){
      this.getData('https://api.sponsr.se/statistics?token='+this.token)
        .then(data => {
          this.statistics = data;
        });
      // console.log(this.name, this.price);
    },
    getSponsored(){
      this.getData('https://api.sponsr.se/sponsored?token='+this.token)
        .then(data => {
          this.organisations = data;
          this.countSponsored();
        });
      // console.log(this.name, this.price);
    },
    toggleActive(organisation, event) {
      event.preventDefault()
      if(organisation.active == 0) {
        this.postData(`https://api.sponsr.se/organisations/${organisation.id}/activate?token=${this.token}`)
        .then(data => {
          if(data.success) {
            organisation.active = 1;
            this.countSponsored();
          }
        });
      } else {
        this.postData(`https://api.sponsr.se/organisations/${organisation.id}/deactivate?token=${this.token}`)
        .then(data => {
          if(data.success) {
            organisation.active = 0;
            this.countSponsored();
          }
        });
      }
    },
    createManualPaymentRequest() {
      this.showDirectPayment = true;
    },
    async postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }

}
</script>