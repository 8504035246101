import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.use(VueRouter)

import Home from './components/Home.vue';
import Transparency from './components/Transparency.vue';
import Dashboard from './components/Dashboard.vue';
import Purposes from './components/Purposes.vue';
import Payments from './components/Payments.vue';
import Payment from './components/Payment.vue';
import Settings from './components/Settings.vue';
import './registerServiceWorker'


const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: Home },
    { path: '/transparency', component: Transparency },
    { path: '/dashboard', component: Dashboard },
    { path: '/purposes', component: Purposes },
    { path: '/payment', component: Payments },
    { path: '/payment/:id', component: Payment },
    { path: '/settings', component: Settings },
  ]
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
