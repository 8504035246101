<template>
  <div>

  <Nav page='payment' />

  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">



      <div class="mt-5 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
          <!-- Description list-->
          <section aria-labelledby="applicant-information-title">
            <div class="bg-white shadow sm:rounded-lg">
              <div class="px-4 py-5 sm:px-6">
                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                  Betalningsinformation
                </h2>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Betalningsform
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{payment.method}}
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Betalare
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      +46727497530
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Belopp
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{payment.amount}} kr
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Efter avgifter
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ (payment.amount*0.97)-1 }} kr
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Betalningsbegäran
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{payment.request_generated_at}}
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Betalningsbekräftelse
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span v-if="payment.request_filled == 1">{{payment.request_sent_at}}</span>
                      <span v-if="payment.request_filled == 0">Ej fastställt</span>
                    </dd>
                  </div>
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      Sammanfattning
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      Genom betalningen på <strong>{{payment.amount}} kr</strong> kommer vi att utbetala <strong>{{ Math.round(((payment.amount*0.97)-1)/payment.specification.length) }} kr</strong> till de erlagda ändamålen. <br>Du ska ha ett <strong class="text-rose-600">STORT TACK</strong> för att du hjälper organisationerna genom att sponsra pengar.
                    </dd>
                  </div>

                  <div class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      Bifogade filer
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
                        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div class="w-0 flex-1 flex items-center">
                            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" x-description="Heroicon name: solid/paper-clip" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"></path>
</svg>
                            <span class="ml-2 flex-1 w-0 truncate">
                              Kvitto {{payment.request_code}}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0">
                            <a v-bind:href="'https://api.sponsr.se/invoices/'+payment.request_code" target="_blank" class="font-medium text-blue-600 hover:text-blue-500">
                              Visa kvitto
                            </a>
                          </div>
                        </li>
                      </ul>
                    </dd>
                  </div>
                  
                </dl>
              </div>
            </div>
          </section>

        </div>

        <section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-1">
          <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Fördelning</h2>

            <!-- Activity Feed -->
            <div class="mt-6 flow-root ">
              <ul class="-mb-8">
                
                  <li v-for="spec in payment.specification" :key="spec.id">
                    <div class="relative pb-8">
                      <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex space-x-3">
                        <div>
                          <img class="h-8 w-8 rounded-full  ring-8 ring-white" :src="spec.logo" alt="">
                        </div>
                        <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                          <div>
                            <p class="text-sm font-medium text-gray-900">{{spec.name}}</p>
                          </div>
                          <div class="text-right text-sm whitespace-nowrap text-gray-500">
                            <span>{{ Math.round(((payment.amount*0.97)-1)/payment.specification.length) }} kr</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                
                  <li>
                    <div class="relative pb-8">
                      <div class="relative flex space-x-3">
                        <div>
                          <span class="h-8 w-8 rounded-full bg-rose-600 flex items-center justify-center ring-8 ring-white">
                            <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                            </svg>
                          </span>
                        </div>
                        <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                          <div>
                            <p class="text-sm text-gray-500">Utbetalas <a href="#" class="font-medium text-gray-900">2021-12-01</a></p>
                          </div>
                          <div class="text-right text-sm whitespace-nowrap text-gray-500">
                            <span>{{ Math.round((payment.amount*0.97)-1) }} kr</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                
              </ul>
            </div>
            <div class="mt-6 flex flex-col justify-stretch">
              <button @click="createManualPaymentRequest" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Donera igen
              </button>
            </div>
          </div>
        </section>
      </div>

    </div>
    <Footer/>
    <Notification/>
  </div>
</template>

<style>
body {
  --tw-bg-opacity: 1;
  background-color:  rgba(243, 244, 246, var(--tw-bg-opacity));;
}
</style>

<script>

import Nav from './Nav.vue'
import Notification from './Notification.vue'
import Footer from './Footer.vue'

export default {
  name: 'Payments',
  components: {
    Nav,
    Notification,
    Footer
  },
  data: function() {
    return {
      token: null,
      payment: [],
    }
  },
  beforeMount(){
    this.checkToken()
    this.getPayment()
  },
  methods: {
    checkToken() {
      let token = localStorage.getItem('token');
      if(token == null) {
        window.location.href = "/";
      } else {
        this.token = token;
      }
    },
    getPayment(){
      this.getData(`https://api.sponsr.se/payments/${this.$route.params.id}?token=${this.token}`)
        .then(data => {
          
          if(data.length == 0) {
            window.location.href = "/payment";
          } else {
            this.payment = data;
          }
          
        });
      // console.log(this.name, this.price);
    },
    createManualPaymentRequest() {
      this.postData(`https://api.sponsr.se/payments?token=${this.token}`)
        .then(data => {
          console.log(data);
          window.location.href = "/payment";
        });
    },
    async postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }

}
</script>