<template>
  <div>
    <Alert v-bind:title="alert.title" v-bind:type="alert.type" v-bind:message="alert.message" v-bind:show="alert.show" />
    <Nav page='settings' />
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="mt-5">


        <div>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Personliga uppgifter</h3>
                <p class="mt-1 text-sm text-gray-600">
                 Valfri information om dig som sponsor, vi kommer aldrig publicera namn eller andra känsliga uppgifter offentligt.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form v-on:submit="saveUserSettings">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">
                      <div class="col-span-6 sm:col-span-3">
                        <label for="first_name" class="block text-sm font-medium text-gray-700">Förnamn</label>
                        <input type="text" name="first_name" id="first_name" v-model="user.firstname" autocomplete="given-name" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="last_name" class="block text-sm font-medium text-gray-700">Efternamn</label>
                        <input type="text" name="last_name" id="last_name" v-model="user.lastname" autocomplete="family-name" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="email_address" class="block text-sm font-medium text-gray-700">E-post address</label>
                        <input type="text" name="email_address" id="email_address" v-model="user.email" autocomplete="email" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="phone" class="block text-sm font-medium text-gray-700">Mobilnummer</label>
                        <input disabled type="tel" name="phone" id="phone" autocomplete="phone" v-model="user.phone" class="mt-1 focus:ring-rose-500 focus:border-rose-500 bg-gray-100 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>


                      <div class="col-span-6">
                        <label for="street_address" class="block text-sm font-medium text-gray-700">Gatuadress</label>
                        <input type="text" name="street_address" id="street_address" v-model="user.address" autocomplete="street-address" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="postal_code" class="block text-sm font-medium text-gray-700">Postnummer</label>
                        <input type="text" name="postal_code" id="postal_code" v-model="user.postal_code" autocomplete="postal-code" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-4 lg:col-span-4">
                        <label for="city" class="block text-sm font-medium text-gray-700">Postort</label>
                        <input type="text" name="city" id="city" v-model="user.city" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                    </div>
                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                      Spara
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>


        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Ekonomi</h3>
                <p class="mt-1 text-sm text-gray-600">
                 Ändra hur mycket och när du vill få betalningsbegäran skickad till dig varje månad. Betalningsbegäran skickas alltid den sista dagen i månaden.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form v-on:submit="savePaymentSettings">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">

                      <div class="col-span-6">
                        <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                          <a href="#" @click.prevent="user.amount = 100" :class=" {'bg-rose-600 text-white' : user.amount == 100, 'bg-white text-rose-600' : user.amount != 100}" class="rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                              <span>100kr</span>
                          </a>

                          <a href="#" @click.prevent="user.amount = 200" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 200, 'bg-white text-rose-600' : user.amount != 200}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                              <span>200kr</span>
                          </a>

                          <a href="#" @click.prevent="user.amount = 300" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 300, 'bg-white text-rose-600' : user.amount != 300}" class=" group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                              <span>300kr</span>
                          </a>

                          <a href="#" @click.prevent="user.amount = 400" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 400, 'bg-white text-rose-600' : user.amount != 400}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                              <span>400kr</span>
                          </a>

                          <a href="#" @click.prevent="user.amount = 500" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 500, 'bg-white text-rose-600' : user.amount != 500}" class="rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                              <span>500kr</span>
                          </a>
                        </nav>

                        <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mt-5" aria-label="Tabs">
                            <a href="#" @click.prevent="user.amount = 1000" :class=" {'bg-rose-600 text-white' : user.amount == 1000, 'bg-white text-rose-600' : user.amount != 1000}" class="rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                <span>1000kr</span>
                            </a>

                            <a href="#" @click.prevent="user.amount = 2000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 2000, 'bg-white text-rose-600' : user.amount != 2000}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                <span>2000kr</span>
                            </a>

                            <a href="#" @click.prevent="user.amount = 3000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 3000, 'bg-white text-rose-600' : user.amount != 3000}" class=" group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                <span>3000kr</span>
                            </a>

                            <a href="#" @click.prevent="user.amount = 4000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 4000, 'bg-white text-rose-600' : user.amount != 4000}" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                <span>4000kr</span>
                            </a>

                            <a href="#" @click.prevent="user.amount = 5000" aria-current="page" :class=" {'bg-rose-600 text-white' : user.amount == 5000, 'bg-white text-rose-600' : user.amount != 5000}" class="rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-rose-600 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-rose-500 focus:border-rose-500">
                                <span>5000kr</span>
                            </a>
                        </nav>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="sponsor_amount" class="block text-sm font-medium text-gray-700">Egen summa</label>
                        <input type="text" v-model="user.amount" id="sponsor_amount"  class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <label for="sponsor_period" class="block text-sm font-medium text-gray-700">Hur ofta</label>
                        <select id="sponsor_period" v-model="user.period"  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm rounded-md">
                          <option value="monthly">Varje månad</option>
                          <option value="bi_monthly">Varannan månad</option>
                          <option value="quarterly">Var tredje månad</option>
                        </select>
                      </div>




                    </div>
                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                      Spara
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Notifikationer</h3>
                <p class="mt-1 text-sm text-gray-600">
                  Du bestämmer själv vid vilka tillfällen du tillåter att vi kontaktar dig!
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form v-on:submit="saveNotificationSettings">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <fieldset>
                      <legend class="text-base font-medium text-gray-900">Via e-post</legend>
                      <div class="mt-4 space-y-4">
                        <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="comments" v-model="notifications.purposes" name="comments" type="checkbox" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300 rounded">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="comments" class="font-medium text-gray-700">Nya ändamål</label>
                            <p class="text-gray-500">Få en notis när vi lägger till nya spännande ändamål!</p>
                          </div>
                        </div>
                        <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="candidates" v-model="notifications.payments" name="candidates" type="checkbox" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300 rounded">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="candidates" class="font-medium text-gray-700">Betalningar</label>
                            <p class="text-gray-500">Få ett mail med när vi skickar en betalningsbegäran eller påminnelser.</p>
                          </div>
                        </div>
                        <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="offers" v-model="notifications.other" name="offers" type="checkbox" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300 rounded">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="offers" class="font-medium text-gray-700">Övrigt</label>
                            <p class="text-gray-500">Vid speciella fall kanske vi har hittat på nåt annat kul som vi vill berätta!</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div>
                        <legend class="text-base font-medium text-gray-900">Via SMS</legend>
                        <p class="text-sm text-gray-500">Välj om vi får lov att skicka ut SMS till dig</p>
                      </div>
                      <div class="mt-4 space-y-4">
                        <div class="flex items-center">
                          <input id="push_everything" v-model="notifications.sms" value="all" name="sms" type="radio" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300">
                          <label for="push_everything" class="ml-3 block text-sm font-medium text-gray-700">
                            Allt
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input id="push_email" v-model="notifications.sms" value="same" name="sms" type="radio" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300">
                          <label for="push_email" class="ml-3 block text-sm font-medium text-gray-700">
                            Samma som för e-post
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input id="push_nothing" v-model="notifications.sms" value="none" name="sms" type="radio" class="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300">
                          <label for="push_nothing" class="ml-3 block text-sm font-medium text-gray-700">
                            Jag vill inte ha SMS
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                      Spara
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>


        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Företagskonto</h3>
                <p class="mt-1 text-sm text-gray-600">
                  Är du företagare eller ansvarig för er gåvoverksamhet? Då kan ni få ert månadsbelopp genom en vanlig faktura istället.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <form v-on:submit="saveCompanySettings">
                <div class="shadow overflow-hidden sm:rounded-md">
                  <div class="px-4 py-5 bg-white space-y-6 sm:p-6">

                    <div class="grid grid-cols-6 gap-6">
                      <div class="col-span-6 sm:col-span-3">

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div class="flex items-center">
                          <!-- Enabled: "bg-rose-600", Not Enabled: "bg-gray-200" -->
                          <button @click="isCompany = !isCompany" type="button" :class="{'bg-rose-600' : isCompany, 'bg-gray-200' : !isCompany}" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500" aria-pressed="false" aria-labelledby="annual-billing-label">
                            <span class="sr-only">Använd företagskonto</span>
                            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                            <span aria-hidden="true" :class="{'translate-x-5' : isCompany, 'translate-x-0' : !isCompany}" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                          </button>
                          <span class="ml-3" id="annual-billing-label">
                            <span class="text-sm font-medium text-gray-900">Aktivera företagskonto</span>
                            <span class="text-sm text-gray-500"> ( Faktura månadsvis )</span>
                          </span>
                        </div>

                      </div>
                    </div>

                    <div v-if="isCompany" class="grid grid-cols-6 gap-6">
                      <div class="col-span-6 sm:col-span-3">
                        <label for="first_name" class="block text-sm font-medium text-gray-700">Namn</label>
                        <input type="text" name="first_name" id="first_name" v-model="company.name" autocomplete="given-name" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="last_name" class="block text-sm font-medium text-gray-700">Organisationsnummer</label>
                        <input type="text" name="last_name" id="last_name" v-model="company.org_number" autocomplete="family-name" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="email_address" class="block text-sm font-medium text-gray-700">E-post address (För faktura)</label>
                        <input type="text" name="email_address" id="email_address" v-model="company.email" autocomplete="email" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="phone" class="block text-sm font-medium text-gray-700">Telefonnr</label>
                        <input type="tel" name="phone" id="phone" autocomplete="phone" v-model="company.phone" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>


                      <div class="col-span-6">
                        <label for="street_address" class="block text-sm font-medium text-gray-700">Adress</label>
                        <input type="text" name="street_address" id="street_address" v-model="company.address" autocomplete="street-address" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label for="postal_code" class="block text-sm font-medium text-gray-700">Postnummer</label>
                        <input type="text" name="postal_code" id="postal_code" v-model="company.postal_code" autocomplete="postal-code" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                      <div class="col-span-6 sm:col-span-4 lg:col-span-4">
                        <label for="city" class="block text-sm font-medium text-gray-700">Postort</label>
                        <input type="text" name="city" id="city" v-model="company.city" class="mt-1 focus:ring-rose-500 focus:border-rose-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                      </div>

                    </div>
         
                  </div>
                  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                      Spara
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Footer/>
    <Notification/>
  </div>
</template>

<style>
body {
  --tw-bg-opacity: 1;
  background-color:  rgba(243, 244, 246, var(--tw-bg-opacity));;
}
</style>

<script>

import Alert from './Alert.vue'
import Nav from './Nav.vue'
import Notification from './Notification.vue'
import Footer from './Footer.vue'

export default {
  name: 'Settings',
  components: {
    Alert,
    Nav,
    Notification,
    Footer
  },
  data: function() {
    return {
      token: null,
      payments: [],
      activePayments: [],
      isCompany: false,
      user: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        address: null,
        postal_code: null,
        city: null,
        amount: 0,
        period: ""
      },
      company: {
        name: null,
        org_number: null,
        email: null,
        phone: null,
        address: null,
        postal_code: null,
        city: null,
      },
      notifications: {
        purposes: false,
        other: false,
        payments: false,
        sms: 'none'
      },
      alert: {
        title: null,
        type: null,
        message: null,
        show: false
      }
    }
  },
  beforeMount(){
    this.checkToken()
    this.getUserSettings()
    this.getUserNotificationSettings()
  },
  methods: {
    checkToken() {
      let token = localStorage.getItem('token');
      if(token == null) {
        window.location.href = "/";
      } else {
        this.token = token;
      }
    },
    getUserSettings(){
      this.getData('https://api.sponsr.se/settings?token='+this.token)
        .then(data => {
          this.user = data;
          if(this.user.company_id !== null) {
            this.isCompany = true;
            this.getCompanySettings();
          }
        });
      // console.log(this.name, this.price);
    },
    getCompanySettings(){
      this.getData('https://api.sponsr.se/settings/company?token='+this.token)
        .then(data => {
          this.company = data;
        });
      // console.log(this.name, this.price);
    },
    getUserNotificationSettings(){
      this.getData('https://api.sponsr.se/settings/notifications?token='+this.token)
        .then(data => {
          this.notifications = data;
        });
    },
    saveUserSettings(e) {
      e.preventDefault();
      this.postData('https://api.sponsr.se/settings?token='+this.token, this.user)
        .then(data => {
          console.log(data);
          this.alert.title = "Sparat";
          this.alert.type = "success";
          this.alert.message = "Personuppgifterna har sparats!";
          this.alert.show = true;
          setTimeout(() => this.alert.show = false, 3000 );
        });
    },
    saveCompanySettings(e) {
      e.preventDefault();
      this.postData('https://api.sponsr.se/settings/company?token='+this.token, this.company)
        .then(data => {
          console.log(data);
          this.alert.title = "Sparat";
          this.alert.type = "success";
          this.alert.message = "Företagsuppgifterna har sparats!";
          this.alert.show = true;
          setTimeout(() => this.alert.show = false, 3000 );
        });
    },
    savePaymentSettings(e) {
      e.preventDefault();
      this.postData('https://api.sponsr.se/settings/payment?token='+this.token, this.user )
        .then(data => {
          console.log(data);
          this.alert.title = "Sparat";
          this.alert.type = "success";
          this.alert.message = "Betalningsuppgifterna har sparats!";
          this.alert.show = true;
          setTimeout(() => this.alert.show = false, 3000 );
        });
    },
    saveNotificationSettings(e) {
      e.preventDefault();
      this.postData('https://api.sponsr.se/settings/notifications?token='+this.token, this.notifications)
        .then(data => {
          console.log(data);
          this.alert.title = "Sparat";
          this.alert.type = "success";
          this.alert.message = "Notifieringsinställningarna har sparats";
          this.alert.show = true;
          setTimeout(() => this.alert.show = false, 3000 );
        });
    },
    async postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }

}
</script>