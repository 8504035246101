<template>
<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ]
  }
  ```
-->
<div class="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
  <div class="max-w-max lg:max-w-7xl mx-auto">
    <div class="relative z-10 mb-8 md:mb-2 md:px-6">
      <div class="text-base max-w-prose lg:max-w-none">
        <h2 class="leading-6 text-rose-600 font-semibold tracking-wide uppercase">Transparenspolicy</h2>
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Så pengarna hamnar rätt</p>
      </div>
    </div>
    <div class="relative">
      <svg class="hidden md:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
        <defs>
          <pattern id="95e8f2de-6d30-4b7e-8159-f791729db21b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
      </svg>
      <svg class="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
        <defs>
          <pattern id="7a00fe67-0343-4a3c-8e81-c145097a3ce0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
      </svg>
      <div class="relative md:bg-white md:p-6">
        <div class="lg:grid lg:grid-cols-2 lg:gap-6">
          <div class="prose  prose-lg text-gray-500 lg:max-w-none">
            <p>För oss är det superviktigt att du ska veta att dina pengar hamnar i rätt ficka, därför redogör vi alla tänkbara avgifter som kan uppkomma i detta dokument.</p>
            <p>Att saker och ting kostar pengar är ju ingen hemlighet och Sponsr AB är just ett AB, vi har ett vinstsyfte och det är ingenting vi på något sätt försöker dölja, däremot redovisar vi allting så att du slipper undra!</p>
            <ol>
              <li>Sponsr AB har ett vinstsyfte.</li>
              <li>Sponsr AB har ett åtagande mot investerare.</li>
              <li>Sponsr AB har ett åtagande mot ändamålsmottagare.</li>
              <li>Sponsr AB har ett åtagande mot dig.</li>
            </ol>
            <p>Med listan ovan kan vi alltså klargöra att vi vill se till att alla intressenter som använder eller är involverade i systemet blir nöjda!</p>
          </div>
          <div class="mt-6 prose prose-lg text-gray-500 lg:mt-0">
            <p>
              När du sponsrar ett eller flera ändamål så tar vi ut en avgift på <strong>3%</strong> som går till Sponsr AB för vidareutveckling samt administration. Övriga kostnader är betalningsprocessorn (Swish) som tar en avgift på <strong>1kr</strong> samt banken som tar <strong>50kr/mån</strong> för att möjliggöra betalning med Swish.
              Vår serverkostnad uppgår i skrivande stund till <strong>286kr/mån</strong>. 
              </p>
            <p>För att ge ett exempel så säger vi att du väljer att donera 1000kr till <router-link to="/purposes/radda-barnen" class="text-rose-600">Rädda barnen</router-link>, <router-link to="/purposes/wwf" class="text-rose-600">Världsnaturfonden</router-link> och <router-link to="/purposes/bris" class="text-rose-600">BRIS</router-link> och att du är <strong>ensam sponsor</strong> på sidan.</p>
            <p>Vi utgår från att fördelningen är jämn (<strong>33%</strong>) till respektive ändamål. Sponsr AB tar alltså <strong>30kr</strong> i avgift och Swish tar <strong>1kr</strong> i avgift. Banken och serveravgiften dras från det ändamål som har störst sponsring, i detta fallet är det jämlikt.</p>
            <p>Ändamålen får alltså dela på <strong class="text-rose-600">633kr</strong> och får då <strong class="text-rose-600">211kr</strong> var.</p>
            <small><em>Vi förbehåller oss rätten att ändra villkoren för att vi ska möjliggöra att kunna driva tjänsten vidare, detta kommer att meddelas till alla sponsorer via e-post, sociala medier eller övriga kanaler.</em></small>
          </div>
        </div>
        <div class="inline-flex rounded-md shadow md:mt-8">
          <router-link to="/" class="flex items-center justify-center px-5 py-3 border border-rose-600 text-base font-medium rounded-md text-rose-600 mr-10 bg-white hover:bg-rose-500">
            Tillbaka
          </router-link>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  name: 'Transparency',
  props: {
    msg: String
  }
}
</script>
