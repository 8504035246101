<template>
   <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
        enter-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
        leave-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <div class="fixed inset-0 overflow-hidden z-50" v-if="show" @click="hidePanel">
          <div class="absolute inset-0 overflow-hidden">
            <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" aria-labelledby="slide-over-heading" @click.stop>
              <div class="w-screen max-w-2xl">
                <form class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="px-4 py-6 bg-gray-50 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">
                            {{purpose.name}}
                          </h2>
                          <p class="text-sm text-gray-500">
                            Här kommer lite mer information gällande ändamålet.
                          </p>
                        </div>
                        <div class="h-7 flex items-center">
                          <button type="button" @click="hidePanel" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rose-500">
                            <span class="sr-only">Stäng panel</span>
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="px-4 py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      <div class="mt-5 prose prose-indigo text-gray-500">
                        <img class="object-contain" :src="purpose.logo" alt="">
                        <p class="whitespace-pre-line">{{purpose.information}}</p>
                      </div>
                      </div>

                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    <div class="space-x-3 flex justify-end">
                      <button type="button" @click="hidePanel" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                        Avbryt
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
    </transition>


</template>

<script>

export default {
  name: 'Purpose',
  props: ['show', 'purpose'],
  data: function() {
      return {
          token: null,
      }
  },
  beforeMount(){
      this.checkToken()
  },
  methods: {
    hidePanel: function() {
        this.$emit('hidePurposePanel');
    },
    checkToken() {
      let token = localStorage.getItem('token');
      if(token == null) {
        window.location.href = "/";
      } else {
        this.token = token;
      }
    },
    async getData(url = '') {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }
}
</script>