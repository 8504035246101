<template>
  <div>
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex" aria-label="Tabs">
        <router-link to="/dashboard" v-bind:class="{ 'border-rose-500 text-rose-600' : page == 'dashboard', 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300' : page != 'dashboard'}" class=" w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm">
          Översikt
        </router-link>
        <router-link to="/purposes" v-bind:class="{ 'border-rose-500 text-rose-600' : page == 'purposes', 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300' : page != 'purposes'}" class=" w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm">
          Ändamål
        </router-link>
        <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
        <router-link to="/payment" v-bind:class="{ 'border-rose-500 text-rose-600' : page == 'payment', 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300' : page != 'payment'}" class=" w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm">
          Betalning
        </router-link>
        <router-link to="/settings" v-bind:class="{ 'border-rose-500 text-rose-600' : page == 'settings', 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300' : page != 'settings'}" class=" w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm">
          Inställningar
        </router-link>
      </nav>
    </div>
</div>
</template>

<script>

export default {
  name: 'Nav',
  props: ['page']
}
</script>